import { parseOpenApiSpecDocument, parseAsyncApiSpecDocument, parsedDocument, tableOfContents, validationResults } from '@kong/spec-renderer'
import type { ServiceNode, TableOfContentsItem } from '@kong/spec-renderer'
import type { ProductVersionSpecDocument } from '@kong/sdk-portal-js'

export default function useSpecRenderer() {
  const { $portalApi } = useNuxtApp()
  const route = useRoute('apis-api_slug-specifications-spec_id-spec_path')
  const { t } = useI18n()

  // Store the unique key for the spec renderer state from the route params
  const specRendererStateKey = computed((): string => `api-spec-${route.params.api_slug}-spec-${'INSERT_SPEC_ID?'}`)
  // Initialize the spec renderer state Map, utilizing the `specRendererStateKey` as the unique key
  const specRendererStateMap = useState<Map<string, SpecRendererState | undefined>>(() => new Map())
  /** The maximum number of specs to store in the specRendererStateMap */
  const MAX_STORED_SPECS: number = 5
  // Create a computed variable to get the current spec renderer state from the map
  const currentRouteState = computed(() => specRendererStateMap.value?.get(specRendererStateKey.value))
  // Set the state for the current route.
  const specRendererState = useState<SpecRendererState | undefined>(() => currentRouteState.value)
  const specRendererLoading = useState<boolean>(() => false)
  const specRendererError = useState<string>(() => '')

  const currentSpecPath = computed((): string => {
    if (route.name === 'apis-api_slug-specifications-spec_id-spec_path') {
      return `/${route.params.spec_path ? route.params.spec_path?.join('/') : ''}`
    }
    return ''
  })

  /**
 * Fetch or update the spec document table of contents based on if it has already been fetched and stored to the `specRendererStateMap`.
 */
  const fetchOrUpdateSpecDocument = async (): Promise<void> => {
    try {
      // Clear the error state
      specRendererError.value = ''

      // If no API slug, exit early
      if (!route.params.api_slug) {
        return
      }

      // Initialize a spec ID from the route params, or fetch from the API
      let specId: string = route.params.spec_id || ''
      if (!specId) {
        const api = await $portalApi('/api/v3/apis/{apiIdOrSlug}', {
          path: {
            apiIdOrSlug: computed(() => route.params.api_slug).value,
          },
        })

        specId = api.specifications?.[0]?.id || ''
      }

      // If still no spec id, exit early
      if (!specId) {
        return
      }

      // If the specRendererState already contains the parsed document from the specRendererStateMap, there's no need to fetch and parse again
      if (currentRouteState.value) {
        // Update the state with the cached data
        specRendererState.value = currentRouteState.value
        return
      }

      specRendererLoading.value = true
      const specDocument: ProductVersionSpecDocument = await $portalApi('/api/v3/apis/{apiIdOrSlug}/specifications/{specId}', {
        path: {
          apiIdOrSlug: computed(() => route.params.api_slug).value,
          specId: computed(() => specId).value,
        },
      })

      if (specDocument?.content) {
        // if content is more than 3MB - we will do client side only
        const tooBigForSsr = specDocument?.content.length > 3000000
        // TODO: portalApi called above, soon to return correct api_type (as of today it returns openapi even for async api spec)
        // so for now we look at the content, and will not run async on the client.
        // When the Portal API is updated to return both `"type": "oas3 | async" etc. we can update this logic.
        // @ts-ignore api_type will returns asyncapi
        const isAsyncApi = specDocument.api_type === 'asyncapi' || specDocument.content.startsWith('asyncapi')
        if (isAsyncApi) {
          if (import.meta.client) {
            // we will only call Async parsing in client
            await parseAsyncApiSpecDocument(specDocument?.content, { currentPath: currentSpecPath.value, traceParsing: false, enforceResetBeforeParsing : true })
          }
        } else {
          // openApi spec we shall parse on both client and server, unless it is too big
          if (import.meta.client || !tooBigForSsr) {
            await parseOpenApiSpecDocument(specDocument?.content, { currentPath: currentSpecPath.value, traceParsing: false, enforceResetBeforeParsing: true })
          }
        }
        //we only  want to populate state if parsing succeeded, but when parsedDocument is empty, we want to re-try parsing on client site by not populating Map on the server
        if (parsedDocument.value) {
          specRendererStateMap.value.set(specRendererStateKey.value, {
            key: specRendererStateKey.value,
            parsedDocument: <ServiceNode>parsedDocument.value,
            toc: <TableOfContentsItem[]>tableOfContents.value,
            validationResults: validationResults.value,
          })

          // Update the state with the fetched data
          specRendererState.value = currentRouteState.value

          // If there are greater than MAX_STORED_SPECS specs stored in the map, clear out the oldest one
          if (specRendererStateMap.value.size > MAX_STORED_SPECS) {
            // Get the oldest key in the map
            const oldestKey = specRendererStateMap.value.keys().next().value || ''
            // If the oldest key is not the current key, delete it from the map
            if (!!oldestKey && oldestKey !== specRendererStateKey.value) {
              specRendererStateMap.value.delete(oldestKey)
            }
          }
        }
      } else {
        specRendererState.value = undefined
        specRendererError.value = t('errors.specs.not_found')
      }
    } catch (error: any) {
      console.error('Error fetching spec document', error)
      specRendererState.value = undefined
      specRendererError.value = parseApiError(error) || t('errors.specs.fetch')
    } finally {
      specRendererLoading.value = false
    }
  }

  return {
    specRendererState,
    specRendererLoading,
    specRendererError,
    // Methods
    fetchOrUpdateSpecDocument,
  }
}
