import { parseMarkdown } from '@nuxtjs/mdc/runtime'
import type { MDCParserResult } from '@nuxtjs/mdc'
import type { FetchError } from 'ofetch'

export default async function useApiDocument() {
  const route = useRoute('apis-api_slug-docs-document_slug')
  const apiSlug = computed((): string => route.params.api_slug || '')
  const documentSlug = computed(() => route.params.document_slug?.join('/') || '')

  const parsedApiDocumentMap = useState<Map<string, ParsedApiDocument>>('parsed-api-document-map', () => new Map())
  const currentRouteDocumentKey = computed((): string => apiSlug.value && documentSlug.value ? `api-document-${apiSlug.value}-${documentSlug.value}` : '')
  // Export useState variables for use in `[document_slug].vue` and `SidebarDocumentsToc.vue`
  const parsedApiDocument = useState<ParsedApiDocument | undefined>('parsed-api-document', () => undefined)
  const parsedApiDocumentPending = useState<boolean>('parsed-api-document-pending', () => false)
  const parsingDocumentMarkdown = useState<boolean>('parsing-api-document-markdown-pending', () => false)
  const parsedApiDocumentError = useState<FetchError | null>('parsed-api-document-error', () => null)

  const { data: cachedDocumentData } = useNuxtData(currentRouteDocumentKey.value)
  const { transform, getCachedData } = serveCachedData()
  const { data: apiDocument, status: apiDocumentStatus, error: apiDocumentError, refresh: fetchApiDocumentForRoute } = await usePortalApi(
    '/api/v3/apis/{apiIdOrSlug}/documents/{documentIdOrSlug}',
    {
      path: {
        apiIdOrSlug: apiSlug,
        documentIdOrSlug: documentSlug,
      },
      key: currentRouteDocumentKey.value,
      default: cachedDocumentData.value,
      dedupe: 'defer', // Do not make new requests if a request is already in progress
      immediate: !!apiSlug.value && !!documentSlug.value, // Fetch the document immediately if the api_slug and document_slug are present in the route
      watch: false, // Important: Do not watch the URL so that we can manually trigger subsequent fetches
      pick: ['id', 'title', 'slug', 'content'], // Only pick the necessary properties from the API response
      // Serve cached data if exists and not expired
      transform,
      getCachedData,
    },
  )

  // Watch the apiDocument for changes and parse the document content
  watch(() => apiDocument.value?.content, async (content) => {
    try {
      // Only trigger the loading state in the client
      if (import.meta.client) {
        parsingDocumentMarkdown.value = true
      }

      const parsedDoc: MDCParserResult = await parseMarkdown(content || '<span class="empty-document" />', {
        toc: {
          depth: 5,
          searchDepth: 5,
        },
      })

      const parsedDocumentResult = {
        // Add the properties from the actual API Document for use in other components
        id: apiDocument.value?.id || '',
        // TODO: Should we switch to using the markdown front matter `title` if set?
        title: apiDocument.value?.title || parsedDoc.data.title || '',
        slug: apiDocument.value?.slug || '',
        // Add the parsed document
        ...parsedDoc,
      }

      // Set the parsed document as the current document
      parsedApiDocument.value = parsedDocumentResult

      // Reset the loading state
      parsingDocumentMarkdown.value = false

      // If the current key is set (meaning it was able to determine the route params), store the parsed document in the map
      if (currentRouteDocumentKey.value) {
        // Add the parsed document to the map
        parsedApiDocumentMap.value.set(currentRouteDocumentKey.value, parsedDocumentResult)
      }
    } catch (err: any) {
      console.error('Error parsing api document markdown:', err)
    }
  }, {
    immediate: true,
  })

  // Update the useState pending variable
  watch([apiDocumentStatus, parsingDocumentMarkdown], ([newStatus, newParsing]) => {
    parsedApiDocumentPending.value = newStatus === 'pending' || newParsing === true
  }, {
    immediate: true,
  })

  // Update the useState error variable
  watch(apiDocumentError, (error) => {
    parsedApiDocumentError.value = error || null
  }, {
    immediate: true,
    deep: true,
  })

  // When the document_slug changes, fetch the new document
  watch([apiSlug, documentSlug], async ([newApiSlug, newDocumentSlug]) => {
    if (newApiSlug && newDocumentSlug) {
      await getDocumentForCurrentRoute()
    }
  })

  /**
   * Retrieves the document for the current route. If the document already exists in the map, it sets it as the current document; otherwise, it fetches the document.
   */
  const getDocumentForCurrentRoute = async (): Promise<void> => {
    // If the document already exists in the map, set it as the current document
    if (parsedApiDocumentMap.value.has(currentRouteDocumentKey.value)) {
      parsedApiDocument.value = parsedApiDocumentMap.value.get(currentRouteDocumentKey.value)
    } else if (apiSlug.value && documentSlug.value) {
      // Otherwise, fetch the document
      await fetchApiDocumentForRoute({ dedupe: 'defer' })
    }
  }

  return {
    getDocumentForCurrentRoute,
  }
}
