<template>
  <li
    v-if="document"
    :data-testid="document.id"
  >
    <NuxtLink
      class="document-link"
      :class="{ 'active': route.path === currentDocumentPath }"
      :href="currentDocumentPath"
    >
      <div class="document-title">
        {{ document.title }}
      </div>
    </NuxtLink>
    <ol v-if="document?.children?.length">
      <SidebarDocumentsItem
        v-for="child in document.children"
        :key="child.id"
        :document="child"
        :parent-slug="currentDocumentSlug"
      />
    </ol>
  </li>
</template>

<script setup lang="ts">
const props = defineProps({
  document: {
    type: Object as PropType<PortalApiResponseTemp<'list-api-documents'>['data'][0]>,
    default: () => undefined,
  },
  // The slug of the parent document, if any
  parentSlug: {
    type: String,
    default: '',
  },
})

const route = useRoute('apis-api_slug')
const router = useRouter()
// Determine the document slug (e.g. `parent/child`)
const currentDocumentSlug = computed((): string => [props.parentSlug, props.document?.slug].filter(Boolean).join('/'))
// Resolve the current document URL
const currentDocumentPath = computed((): string => router.resolve({
  name: 'apis-api_slug-docs-document_slug',
  params: {
    api_slug: route.params.api_slug,
    document_slug: currentDocumentSlug.value.split('/'),
  },
}).fullPath)
</script>

<style lang="scss" scoped>
li {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: var(--kui-space-0, $kui-space-0) var(--kui-space-0, $kui-space-0) var(--kui-space-20, $kui-space-20);

  &:last-of-type {
    margin-bottom: var(--kui-space-0, $kui-space-0);
  }

  .document-item-content {
    width: 100%;
  }
}

a.document-link {
  align-items: center;
  border-radius: var(--kui-border-radius-20, $kui-border-radius-20);
  box-sizing: border-box;
  color: var(--kui-color-text-neutral-strong, $kui-color-text-neutral-strong);
  display: flex;
  font-size: var(--kui-font-size-30, $kui-font-size-30);
  font-weight: var(--kui-font-weight-regular, $kui-font-weight-regular);
  gap: $kui-space-40;
  line-height: var(--kui-line-height-30, $kui-line-height-30);
  padding: var(--kui-space-30, $kui-space-30) var(--kui-space-30, $kui-space-30) var(--kui-space-30, $kui-space-30) var(--kui-space-50, $kui-space-50);
  transition: all 0.2s ease-in-out;
  user-select: none;
  width: 100%;

  &:hover {
    color: var(--kui-color-text-primary, $kui-color-text-primary);
  }

  .document-title {
    pointer-events: none; // Prevent the title from being clickable
    width: 100%;
  }

  &.active {
    color: var(--kui-color-text-primary, $kui-color-text-primary);
  }
}

</style>
