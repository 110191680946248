<template>
  <PageMdcContent v-if="showCustomPageContent" />
  <div v-else>
    <NuxtLayout
      :fallback="(`${DEFAULT_PORTAL_LAYOUT}-document` as LayoutKey)"
      :name="(`${customization?.layout || DEFAULT_PORTAL_LAYOUT}-document` as LayoutKey)"
    >
      <template #page-header>
        <LayoutMobilePageHeader
          :left-button="{ visible: true, text: t('navigation.docs') }"
          :right-button="{ visible: hasMobileDocumentToc, text: t('navigation.on_this_page') }"
        />
      </template>

      <template #sidebar-left>
        <SidebarDocumentsList />
      </template>

      <template #default>
        <SpecRendererContent v-if="isApiSpecRoute" />
        <NuxtPage
          v-else
          :page-key="(route: RouteLocationNormalizedLoaded) => route.path"
        />
      </template>

      <template #sidebar-right>
        <SidebarDocumentsToc />
      </template>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

const { customization } = storeToRefs(usePortalStore())
const sessionStore = useSessionStore()
const route = useRoute()
const { t } = useI18n()
const { runWithContext } = useNuxtApp()
const { fetchOrUpdateSpecDocument } = useSpecRenderer()
const isApiDocRoute = computed((): boolean => route.name === 'apis-api_slug-docs-document_slug')
const isApiSpecRoute = computed((): boolean => route.name === 'apis-api_slug-specifications-spec_id-spec_path')

// Retrieve the parsed API document from the global state, if it exists
const parsedApiDocument = useState<ParsedApiDocument | undefined>('parsed-api-document')
// Inject the page TOC into child components
provide(PageTocInjectionKey, computed(() => parsedApiDocument.value?.toc))
// Determine there is a TOC for the current document
const hasMobileDocumentToc = computed((): boolean => isApiDocRoute.value && !!parsedApiDocument?.value?.toc?.links?.length)

// If the API exists, and we are a non-built-in-page, attempt to show the `PageMdcContent` component.
const showCustomPageContent = computed((): boolean => {
  if (isApiDocRoute.value || isApiSpecRoute.value) {
    return false
  }
  return true
})

// @ts-ignore - route param should exist
const apiEntityDataKey = computed((): string => `api-entity-data-${String(route.params.api_slug || '')}`)
const { transform, getCachedData } = serveCachedData()
const { data: apiEntityData, error: apiEntityDataError, execute: fetchApiEntityData } = await usePortalApi(
  '/api/v3/apis/{apiIdOrSlug}', {
    path: {
      // @ts-ignore - route param should exist
      apiIdOrSlug: computed(() => String(route.params.api_slug || '')),
    },
    // Use the same key as `SidebarDocmentsList.vue` to share the cache
    key: apiEntityDataKey.value,
    immediate: false,
    // Serve cached data if exists and not expired
    transform,
    getCachedData,
  },
)

// Update the robots.txt rule based on the page visibility


// !Important: the rest of the logic should only run if we are not showing custom page content

if (
  !showCustomPageContent.value &&
  // If on the `/apis/{api_slug}/docs` route, do not fetch the API entity data
  route.name !== 'apis-api_slug-docs'
) {
  await fetchApiEntityData({ dedupe: 'defer' })

  // If error fetching the API entity data
  if (apiEntityDataError.value) {
    // If 404 error
    if (apiEntityDataError.value?.statusCode === 404) {
      // Page not found
      throw createError({
        statusCode: 404,
        statusMessage: t('errors.pages.not_found'),
        fatal: true,
      })
    }
    /**
     * For now, leave this disabled as it causes a 500 error if the user loses their session
     * when attempting to navigate to a page that requires auth. Revisit if other issues arise.
     */
    // Other error
    // throw createError({
    //   statusCode: apiEntityDataError.value?.statusCode,
    //   statusMessage: parseApiError(apiEntityDataError.value as any) || t('errors.unexpected'),
    //   fatal: true,
    // })
  }

  watch(() => apiEntityData.value?.name, async (apiName) => {
    // @ts-ignore - possible child route
    if (!route.path.includes('/specifications/') && apiName) {
      runWithContext(async () => {
        useSeoMeta({
          title: () => apiName,
          description: () => apiEntityData.value?.description || '',
          // Add for lastMod support
          articleModifiedTime: () => apiEntityData.value?.updated_at,
        })
      })
    }
  })

  await fetchOrUpdateSpecDocument()

  const { getDocumentForCurrentRoute } = await useApiDocument()
  // Immediately call the function to fetch the current document if on an api document page
  // Important: leave in place as this enables SSR rendering of the document
  // @ts-expect-error - Assert that we could be on the `/apis/{api_slug}/docs/{document_slug}` route.
  if (route.params.document_slug) {
    await getDocumentForCurrentRoute()
  }

  // Important: Now that errors are processed, the page was retrieved and is viewable by the user, so clear any stored login return path cookie
  if (sessionStore.getLoginReturnPath() && !apiEntityDataError.value) {
    sessionStore.setLoginReturnPath(null)
  }

  // When the api slug changes, fetch the new document
  // @ts-ignore - possible child route param
  watch([() => route.params.spec_id, () => apiEntityData.value?.id], async ([newSpecId, newApiId]) => {
    if (newSpecId || newApiId) {
      runWithContext(async () => await fetchOrUpdateSpecDocument())
    }
  })
}

definePageMeta({
  // Removes any trailing slashes from the path
  middleware: 'remove-page-trailing-slash',
})
</script>
