export default function useApiRegistrations() {
  const { $portalApi } = useNuxtApp()

  const keyId = useId()

  const appsPerApiError = useState<Record<string, any> | null>(`apps-per-api-error-${keyId}`, () => null)
  const appsPerApi = useState<PortalApiResponseTemp<'get-api-applications'>['data']>(`apps-per-api-${keyId}`, () => [])

  const getApplicationsPerApi = async (apiIdOrSlug: string | null): Promise<void> => {
    try {
      appsPerApiError.value = null

      if (apiIdOrSlug) {
        const { data } = await $portalApi('/api/v3/apis/{apiIdOrSlug}/applications', {
          path: {
            apiIdOrSlug,
          },
        })

        appsPerApi.value = data
      }
    } catch (e: any) {
      appsPerApiError.value = e
    }
  }

  return {
    getApplicationsPerApi,
    appsPerApi,
    appsPerApiError,
  }
}
