<template>
  <div
    class="sidebar-specs-toc"
    data-testid="sidebar-specs-toc"
  >
    <SpecRendererToc
      v-if="specRendererState?.toc"
      :base-path="tocBasePath"
      :current-path="specPath"
      :table-of-contents="specRendererState.toc"
      toc-scrolling-container=".sidebar-content"
      @item-selected="specTocItemSelected"
    />
  </div>
</template>

<script setup lang="ts">
import { SpecRendererToc } from '@kong/spec-renderer'

const props = defineProps({
  /** The ID of the API specification */
  specificationId: {
    type: String,
    required: true,
  },
})

const route = useRoute('apis-api_slug-specifications-spec_id-spec_path')
const router = useRouter()
const tocBasePath = computed((): string => {
  if (!route.params.api_slug || !props.specificationId) {
    return ''
  }

  return router.resolve({
    name: 'apis-api_slug-specifications-spec_id-spec_path',
    params: {
      api_slug: route.params.api_slug,
      spec_id: props.specificationId,
    },
  }).fullPath
})

const specPath = computed((): string => {
  if (route.fullPath.includes('/specifications/')) {
    return `/${route.params.spec_path ? route.params.spec_path?.join('/') : ''}`
  }
  return ''
})

const { specRendererState } = useSpecRenderer()
const { finish } = useLoadingIndicator()

/** Navigate to the spec path when a toc item is selected */
const specTocItemSelected = async (id: string): Promise<void> => {
  if (!id) {
    return
  }

  // Ensure to await the route change
  await router.push({ path: `${tocBasePath.value}${id}` })
  // Clear the loading indicator
  finish({ force: true })
}
</script>

<style lang="scss" scoped>
.sidebar-specs-toc {
  padding: var(--kui-space-70, $kui-space-70);
}
</style>
