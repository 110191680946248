<template>
  <div>
    <PageToolbar :title="apiEntityData?.name || ''">
      <template
        v-if="context && context.authentication_enabled === true"
        #actions
      >
        <ClientOnly>
          <PortalKButton
            v-if="showRegisterButton"
            :size="isPhabletWidth ? 'medium' : 'small'"
            @click="showCreateFlow = true"
          >
            {{ t('actions.use_this_api') }}
          </PortalKButton>
        </ClientOnly>
      </template>
    </PageToolbar>

    <ClientOnly>
      <AppsCreateWithRegistration
        v-if="context && context.authentication_enabled === true && showRegisterButton"
        :api-auth-strategy-id="apiAuthStrategyId"
        :can-register="showRegisterButton"
        :is-visible="showCreateFlow"
        :with-credentials="canCreateCredentials"
        @close="showCreateFlow = false"
      />
    </ClientOnly>
    <SpecDocument
      v-if="specRendererState && specRendererState.parsedDocument && specPath"
      :key="specRendererState.key"
      :allow-content-scrolling="true"
      :base-path="specDocumentBasePath"
      :current-path="specPath"
      :document="specRendererState.parsedDocument"
      :spec-url="urlForInsomnia || ''"
      :table-of-contents="specRendererState.toc"
      @content-scrolled="handleSpecContentScroll"
      @item-selected="handleSpecContentScroll"
    />

    <PortalKAlert
      v-else-if="!specRendererLoading && specRendererError"
      appearance="danger"
      data-testid="spec-renderer-error"
      show-icon
      type="info"
    >
      {{ specRendererError }}
    </PortalKAlert>
  </div>
</template>

<script setup lang="ts">
import { SpecDocument, findMatchingNode } from '@kong/spec-renderer'
import { KUI_BREAKPOINT_PHABLET } from '@kong/design-tokens'
import type { NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router'
import { until } from '@vueuse/core'
import type { ServiceChildNode } from '@kong/spec-renderer'

const { context } = storeToRefs(usePortalStore())
const router = useRouter()
const route = useRoute('apis-api_slug-specifications-spec_id-spec_path')

const specPath = computed((): string => `/${route.params.spec_path ? route.params.spec_path?.join('/') : ''}`)
const { specRendererState, specRendererLoading, specRendererError } = useSpecRenderer()
const requestUrl = useRequestURL()
const { transform, getCachedData } = serveCachedData()
const { t } = useI18n()

const { apiData } = useAppRegistrationCRUD()

const keyId = useId()

const { canRegister, showRegisterButton } = useCanRegisterApp()

watch([() => context.value?.rbac_enabled, () => apiData.value?.auth_strategies], async ([rbacEnabled, strategies]) => {
  await canRegister({ slug: route.params.api_slug, authStrategies: strategies || [] })
}, {
  immediate: true,
})

const canCreateCredentials = computed((): boolean => apiData.value?.auth_strategies?.[0]?.credential_type as unknown === CREDENTIAL_TYPE.KEY_AUTH)
const apiAuthStrategyId = computed((): string => Array.isArray(apiData.value?.auth_strategies) ? apiData.value?.auth_strategies?.[0]?.id || '' : '')

const apiEntityDataKey = computed((): string => `api-entity-data-${route.params.api_slug}`)
const { data: apiEntityData } = await usePortalApi(
  '/api/v3/apis/{apiIdOrSlug}', {
    path: {
      apiIdOrSlug: computed(() => String(route.params.api_slug || '')),
    },
    // Use the same key as `[api_slug].vue` to share the cache
    key: apiEntityDataKey.value,
    // Serve cached data if exists and not expired
    transform,
    getCachedData,
  },
)

apiData.value = apiEntityData.value

const specDocumentBasePath = computed((): string => {
  if (!apiEntityData.value || !apiEntityData.value?.specifications?.[0]?.id) {
    return ''
  }

  return router.resolve({
    name: 'apis-api_slug-specifications-spec_id-spec_path',
    params: {
      api_slug: apiEntityData.value.slug,
      spec_id: apiEntityData.value?.specifications?.[0]?.id,
    },
  }).fullPath
})

const urlForInsomnia = computed(():string | null => {
  // for portals where authentication is enabled, return null
  if (context.value?.authentication_enabled === true) {
    return null
  }
  const origin = import.meta.server ? requestUrl.origin : window.location.origin

  return `${origin}/api/v3/apis/${route.params.api_slug}/specifications/${apiEntityData.value?.specifications?.[0]?.id}`
})

// Determine the active spec child entity
const specPathData = computed(():ServiceChildNode | null => {
  if (specRendererState.value?.parsedDocument) {
    return findMatchingNode(specRendererState.value?.parsedDocument, `/${route.params.spec_path ? route.params.spec_path.join('/') : ''}`)
  } else {
    return null
  }
})

const { finish } = useLoadingIndicator()

/*
KHCP-15338 there are the cases when spec-renderer is loaded with new spec,
but route object (and therefore specPath are pointed to the previously loaded spec).
As a result of this spec-renderer fires page-not-found event and 404 is shown.
Immediately after, when route object is re-initialized and points to the correct path but it's
too late. we already show 404.

To handel it - instead of listening to the event from spec-renderer, we will be watching for
specPathData, and if undefined here for longer than 300 ms - we will show 404. This allows us the bypass the
very short "asynchronised" state when doc is new but specPath is old.
*/
watch(() => specPathData, async (pathData) => {
  until(pathData).not.toBeNull({ timeout: 300 })
  if (!pathData.value && specPath.value !== '/') {
    showError({
      statusCode: 404,
      statusMessage: t('errors.pages.not_found'),
    })
  }
}, { immediate: true })

const handleSpecContentScroll = async (newPath: string) => {
  await router.push({
    name: 'apis-api_slug-specifications-spec_id-spec_path',
    params: {
      ...route.params,
      spec_path: newPath.replace('/','').split('/'),
    },
  })
  finish({ force: true })
}

// @ts-ignore - parsedDocument name should exist
const title = computed((): string => specPathData.value?.name || specRendererState.value?.parsedDocument?.name ? `${specPathData.value?.name || specRendererState.value?.parsedDocument?.name || ''} | ${apiEntityData.value?.name || ''}` : apiEntityData.value?.name || '')

useSeoMeta({
  // @ts-ignore - parsedDocument name should exist
  title: () => title.value,
  description: () => truncateString(specRendererState.value?.parsedDocument?.data?.description || '', 200), // 200 character limit for meta.description
  // Add for lastMod support
  // articleModifiedTime: () => undefined,
})

const isPhabletWidth = useMediaQuery(`(min-width: ${KUI_BREAKPOINT_PHABLET})`)

const showCreateFlow = useState<boolean>(`show-create-flow-${keyId}`, () => false)

onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
  apiData.value = null
  next()
})

onBeforeRouteUpdate((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
  const isSameApi = 'api_slug' in to.params && 'api_slug' in from.params && to.params.api_slug === from.params.api_slug

  if (!isSameApi) {
    apiData.value = null
  }
})
</script>

<style lang="scss" scoped>
:deep(.spec-renderer-document) {
  scroll-margin-top: 130px; // The height of the navbar plus the page toolbar
}

@media (min-width: $kui-breakpoint-tablet) {
  :deep(.spec-renderer-document) {
    scroll-margin-top: $header-height; // The height of the navbar
  }
}
</style>
