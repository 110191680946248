<template>
  <LayoutSidebar
    align="left"
    class="documents-list-sidebar"
    :class="{ 'open': mobileSidebarLeftIsVisible === true }"
    data-testid="documents-list-sidebar"
  >
    <template #header>
      <SidebarHeader
        badge-appearance="neutral"
        :badge-text="apiData?.version || ''"
        :title="apiData?.name"
        @close="mobileSidebarLeftIsVisible = false"
      />
    </template>

    <template #default>
      <div
        v-if="documents?.data && documents?.data?.length"
        class="documents-list"
      >
        <div v-if="!documentsError">
          <nav>
            <ol>
              <SidebarDocumentsItem
                v-for="doc in documents.data"
                :key="doc.id"
                :document="doc as any"
              />
            </ol>
          </nav>
        </div>
        <p v-else-if="documentsError">
          {{ t('errors.documents.could_not_retrieve') }}
        </p>
      </div>
      <SidebarSpecsToc
        v-if="apiData?.specifications?.[0]?.id"
        :specification-id="apiData.specifications?.[0]?.id"
      />
    </template>
  </LayoutSidebar>
</template>

<script setup lang="ts">
const { mobileSidebarLeftIsVisible } = storeToRefs(useLayoutStore())
const { t } = useI18n()
const route = useRoute('apis-api_slug-specifications-spec_id-spec_path')
const { transform, getCachedData } = serveCachedData()
const { data: documents, error: documentsError } = await usePortalApi(
  '/api/v3/apis/{apiIdOrSlug}/documents', {
    path: {
      apiIdOrSlug: computed(() => String(route.params.api_slug || '')),
    },
    headers: { accept: 'application/vnd.konnect.document-tree+json' },
    key: `api-documents-${route.params.api_slug}`,
    // Serve cached data if exists and not expired
    transform,
    getCachedData,
  },
)

const apiEntityDataKey = computed((): string => `api-entity-data-${route.params.api_slug}`)
const { data: apiData } = await usePortalApi(
  '/api/v3/apis/{apiIdOrSlug}', {
    path: {
      apiIdOrSlug: computed(() => String(route.params.api_slug || '')),
    },
    // Use the same key as `[api_slug].vue` to share the cache
    key: apiEntityDataKey.value,
    // Serve cached data if exists and not expired
    transform,
    getCachedData,
  },
)
</script>

<style lang="scss" scoped>
.documents-list {
  border-bottom: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
  padding: var(--kui-space-70, $kui-space-70);

  ol,
  :deep(ol) {
    list-style: none;
    margin: var(--kui-space-0, $kui-space-0);
    padding: var(--kui-space-0, $kui-space-0);
    width: 100%;
  }

  ol li :deep(ol) {
    margin-left: var(--kui-space-0, $kui-space-0);

    li a {
      padding-left: var(--kui-space-80, $kui-space-80);
    }
  }
}
</style>
