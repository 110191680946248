<template>
  <LayoutSidebar
    v-if="sidebarVisible"
    align="right"
    class="document-toc-sidebar"
    :class="{ 'open': mobileSidebarRightIsVisible === true }"
    data-testid="document-toc-sidebar"
  >
    <template #header>
      <SidebarHeader
        class="sidebar-header-documents-toc"
        @close="mobileSidebarRightIsVisible = false"
      />
    </template>
    <template #default>
      <PageToc
        :depth="3"
        :padding="`var(--kui-space-70, ${KUI_SPACE_70})`"
        :title="t('navigation.on_this_page')"
      />
    </template>
  </LayoutSidebar>
</template>

<script setup lang="ts">
import { KUI_SPACE_70 } from '@kong/design-tokens'

const { mobileSidebarRightIsVisible } = storeToRefs(useLayoutStore())
const { t } = useI18n()
const parsedApiDocument = useState<ParsedApiDocument | undefined>('parsed-api-document')

const route = useRoute('apis-api_slug-docs-document_slug')
const sidebarVisible = computed((): boolean => route.name === 'apis-api_slug-docs-document_slug' && !!parsedApiDocument.value?.toc?.links?.length)
</script>

<style lang="scss" scoped>
.document-toc-sidebar {
  display: flex;

  @media (min-width: $kui-breakpoint-tablet) and (max-width: ($kui-breakpoint-laptop - 1px)) {
    display: none;
  }
}

.sidebar-header-documents-toc {
  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }
}
</style>
