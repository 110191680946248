<template>
  <KSlideout
    class="app-register-api-slideout"
    :close-on-blur="false"
    :close-on-escape="false"
    :has-overlay="false"
    max-width="680px"
    :title="t('actions.add_api_to_app')"
    :visible="isVisible"
    :z-index="1099"
    @close="$emit('close')"
  >
    <div>
      <PortalKAlert
        v-if="listTextError"
        appearance="danger"
        class="app-register-api-error"
        :message="listTextError"
        show-icon
      />

      <AppsList
        :auth-strategy-filter-id="apiAuthStrategyId"
        :grid-column-breakpoints="{ mobile: 1, phablet: 1, tablet: 1, laptop: 1, desktop: 1 }"
        :grid-columns="1"
        :page-sizes="[5, 10, 25, 35]"
        :show-auth-strategy="false"
        :simple-card="true"
      >
        <template #top-actions>
          <PortalKButton
            size="large"
            @click="$emit('create-application', apiAuthStrategyId)"
          >
            <AddCircleIcon /> {{ t('actions.create_app') }}
          </PortalKButton>
        </template>

        <template #footer-actions="{ app }">
          <PortalKBadge
            v-if="isAppRegistered(app.id)"
            appearance="success"
          >
            {{ t('actions.registered') }}
          </PortalKBadge>

          <PortalKButton
            v-else
            :appearance="isAppRegistered(app.id) ? 'secondary' : 'primary'"
            :disabled="registrationInProgress(app.id) || getAppsPerApiLoader"
            @click="handleRegistration(app, isAppRegistered(app.id))"
          >
            <ProgressIcon
              v-if="registrationInProgress(app.id) || getAppsPerApiLoader"
              decorative
            />
            <span v-if="!getAppsPerApiLoader">{{ t('actions.register') }}</span>
          </PortalKButton>
        </template>

        <template #footer-bottom="{ app }">
          <PortalKAlert
            v-if="errorText && app.id === appIdToRegister"
            appearance="danger"
            class="app-registration-error"
            :message="errorText"
            show-icon
          />
        </template>

        <template #dropdown-items="{ app }">
          <KDropdownItem
            :item="{ label: t('actions.view_app'), to: {
              name: 'apps-app_id',
              params: {
                app_id: app.id,
              },
            } }"
          />
          <KDropdownItem
            v-if="isAppRegistered(app.id)"
            danger
            @click="handleRegistration(app, isAppRegistered(app.id))"
          >
            {{ t('actions.unregister_api') }}
          </KDropdownItem>
        </template>
      </AppsList>
    </div>
  </KSlideout>
</template>

<script setup lang="ts">
import { AddCircleIcon, ProgressIcon } from '@kong/icons'

const { t } = useI18n()
const { registerApiToApplication, appRegistrationError, unregisterApiFromApplication, appUnregisterError, apiData } = useAppRegistrationCRUD()
const { showToast } = useToast()
const { getApplicationsPerApi, appsPerApi, appsPerApiError } = useApiRegistrations()

const keyId = useId()

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
})

defineEmits<{
  (e: 'close'): void
  (e: 'create-application', authStrategyId: string): void
}>()

// Hide the document.body overflow when the global page overlay is active
useHeadSafe({
  bodyAttrs: {
    class: computed(() => props.isVisible ? 'overflow-hidden' : ''),
  },
})

const listTextError = computed((): string => appsPerApiError.value ? parseApiError(appsPerApiError.value as any) : '')

const apiAuthStrategyId = computed((): string => Array.isArray(apiData.value?.auth_strategies) ? apiData.value?.auth_strategies?.[0]?.id || '' : '')

const isAppRegistered = (appId: string): boolean => {
  return !!appsPerApi.value.find((app: PortalApiResponseTemp<'get-api-applications'>['data'][0]) => app.id === appId)
}

// Register app to api version
const appIdToRegister = useState<string>(`app-id-to-register-${keyId}`, () => '')
const appNameToRegister = useState<string>(`app-name-to-register-${keyId}`, () => '')

const postRegistrationCallback = async (): Promise<void> => {
  await getApplicationsPerApi(apiData.value?.slug || null)

  appIdToRegister.value = ''
  appNameToRegister.value = ''
}

const handleRegistration = async (app: PortalApiResponseTemp<'get-application'> | PortalApiResponseTemp<'create-application'>, isAppRegistered: boolean): Promise<void> => {
  if (app.id && apiData.value?.id) {
    appIdToRegister.value = app.id
    appNameToRegister.value = app.name
    appRegistrationError.value = null
    appUnregisterError.value = null

    if (isAppRegistered) {
      await unregisterApiFromApplication(app.id, appsPerApi.value.find((el: PortalApiResponseTemp<'get-api-applications'>['data'][0]) => el.id === app.id)?.registration_id || '')

      if (!appUnregisterError.value) {
        showToast({
          message: t('apps.registration.unregister_notification', { appName: app.name, apiName: apiData.value?.name }),
        })

        await postRegistrationCallback()
      }
    } else {
      await registerApiToApplication(app.id, apiData.value?.id || '')

      if (!appRegistrationError.value) {
        showToast({
          message: t('apps.registration.register_notification', { appName: app.name, apiName: apiData.value?.name }),
        })

        await postRegistrationCallback()
      }
    }
  }
}

const registrationInProgress = (appId: string): boolean => {
  return appIdToRegister.value === appId && !appRegistrationError.value
}

const errorText = computed((): string => {
  if (appRegistrationError.value) {
    return appRegistrationError.value.statusCode === 409 ? t('errors.app_registration.conflict',
      { apiName: apiData.value?.name, appName: appNameToRegister.value })
      : parseApiError(appRegistrationError.value as any)
  }

  if (appUnregisterError.value) {
    return parseApiError(appUnregisterError.value as any)
  }

  return ''
})

const getAppsPerApiLoader = useState<boolean>(`apps-per-api-loader-${keyId}`, () => false)

watch(() => props.isVisible, async (visible) => {
  if (!visible) {
    appRegistrationError.value = null
    appsPerApiError.value = null
    appIdToRegister.value = ''
    appNameToRegister.value = ''
  } else {
    if (apiData.value) {
      getAppsPerApiLoader.value = true
      await getApplicationsPerApi(apiData.value.slug)

      getAppsPerApiLoader.value = false
    }
  }
})
</script>

<style scoped lang="scss">
.app-register-api-slideout {
  :deep(.slideout-container) {
    padding-left: var(--kui-space-0, $kui-space-0);
  }

  :deep(.slideout-header) {
    flex-shrink: 0;
    padding-bottom: var(--kui-space-40, $kui-space-40);
    padding-left: var(--kui-space-70, $kui-space-70);
  }

  :deep(.slideout-content) {
    background-color: var(--kui-color-background-neutral-weakest, $kui-color-background-neutral-weakest);
    border-top: var(--kui-border-width-10, $kui-border-width-10) solid var(--kui-color-border, $kui-color-border);
    flex: 1;
    padding-left: var(--kui-space-70, $kui-space-70);
    padding-top: var(--kui-space-80, $kui-space-80);
  }

  &-api-error {
    margin-top: var(--kui-space-70, $kui-space-70);
  }
}

.app-registration-error {
  margin-top: var(--kui-space-70, $kui-space-70);
}
</style>
