<template>
  <div>
    <PortalKAlert
      v-if="appsListError"
      appearance="danger"
      class="apps-list-error"
      :message="appsListError"
      show-icon
    />

    <AppsCreate
      :auth-strategy-id="authStrategyId"
      data-testid="apps-create-modal"
      :is-first-app="!hasApps"
      :is-visible="createModalVisibility"
      :with-credentials="withCredentials"
      :with-registration="canRegister"
      @close="appsCreateCloseCallback"
      @success="appCreateHandler"
    />

    <AppsRegisterApiSlideout
      data-testid="apps-register-slideout"
      :is-visible="appsListVisibility"
      @close="appsListCloseCallback"
      @create-application="showCreateModal"
    />
  </div>
</template>

<script setup lang="ts">
// This component is a wrapper around Create application with credentials + api registration and a slideout with applications list where user can register api to existing apps.
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router'

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  canRegister: {
    type: Boolean,
    required: true,
  },
  withCredentials: {
    type: Boolean,
    default: false,
  },
  apiAuthStrategyId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const id = useId()

const { t } = useI18n()
const { showToast } = useToast()
const { appsExist, appsError } = await useApps({ authStrategyFilterId: props.apiAuthStrategyId })
const { apiData } = useAppRegistrationCRUD()

const hasApps = useState<boolean>(`create-modal-has-app-${id}`, () => false)
const createModalVisibility = useState<boolean>(`create-modal-visibility-${id}`, () => false)
const appsListVisibility = useState<boolean>(`create-modal-apps-list-visibility-${id}`,() => false)
const authStrategyId = useState<string>(`create-modal-auth-strategy-id-${id}`,() => props.apiAuthStrategyId)

const appsListError = computed((): string => appsError.value ? parseApiError(appsError.value as any) : '')

watch(appsExist, (isExist) => {
  hasApps.value = isExist
}, {
  immediate: true,
})

const addToApp = (): void => {
  if (hasApps.value) {
    appsListVisibility.value = true
  } else {
    createModalVisibility.value = true
  }
}

watch(() => props.isVisible, (visibility) => {
  if (visibility) {
    addToApp()
  }
}, {
  immediate: true,
})

const showCreateModal = (id: string): void => {
  appsListVisibility.value = false
  authStrategyId.value = id
  createModalVisibility.value = true
}

const appsCreateCloseCallback = (): void => {
  emit('close')

  createModalVisibility.value = false
}

const appsListCloseCallback = (): void => {
  emit('close')

  appsListVisibility.value = false
}

const appCreateHandler = (): void => {
  createModalVisibility.value = false

  hasApps.value = true

  emit('close')

  showToast({
    message: t('apps.form.create_and_register_notification', { apiName: apiData.value?.name }),
  })
}

onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
  createModalVisibility.value = false
  appsListVisibility.value = false

  emit('close')
  next()
})
</script>

<style scoped lang="scss">
.apps-list-error {
  margin: var(--kui-space-70, $kui-space-70) var(--kui-space-0, $kui-space-0);
}
</style>
